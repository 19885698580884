<div class="banner-div" [class.dark-theme]="isDarkTheme">
  <div class="ontario-row">
    <div id="title" class="ontario-header__logo-container ontario-columns ontario-small-9">
      {{title}}
    </div>
    <div class="ontario-columns ontario-small-3 margin-top-space" [class.ontario-padding-bottom-16-!]="isLoggedIn">
      <div class="spacer-div"></div>
      <account id="account" *ngIf="isLoggedIn" class="account-drop-down-spacing" [isDarkTheme]="isDarkTheme"></account>
      <login id="login" *ngIf="!isLoggedIn"></login>
    </div>
  </div>
</div>

<div class="ontario-critical-alert">
  <div class="ontario-row" style="margin: 0 !important;">
      <div class="ontario-column ontario-small-12" style="background-color: #FFD440 !important; margin: 0 !important;">
          <div class="ontario-critical-alert__body">
              <div class="ontario-critical-alert__icon">
                  <svg class="ontario-icon" alt="" aria-hidden="true" focusable="false" sol:category="primary" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
                      <use href="#ontario-icon-critical-alert-warning"></use>
                  </svg>
              </div>
              <p>{{'General.NewAlert' | translate}}</p>
          </div>
      </div>
  </div>
</div>
